import React from "react"

import { Link } from "gatsby"
import Obfuscate from 'react-obfuscate';

import "./_ContactBar.css"

import MailIcon from "../../assets/svg/icons/mail.svg"
import PhoneIcon from "../../assets/svg/icons/phone.svg"
import LocationIcon from "../../assets/svg/icons/directions.svg"
import SupportIcon from "../../assets/svg/icons/support.svg"
import ProjectsIcon from "../../assets/svg/icons/browser.svg"

import TeamViewerLogo from "../../assets/svg/logos/teamviewer.svg"
import HilfeLogo from "../../assets/svg/logos/help.svg"

const ContactBar = () => {
  return (
    <nav className="contactbar text_big">

      <div className="footer-link">
        <Obfuscate tel="0043557422538" aria-label="Rufen Sie uns direkt an">
          <aside className={"icon"}>
            <PhoneIcon />
          </aside>
        </Obfuscate>
        <Obfuscate className="text" tel="0043557422538" aria-label="Rufen Sie uns direkt an">
          +43 (0)5574 22538
        </Obfuscate>
      </div>

      <div className="footer-link">
        <Obfuscate email="office@netengine.at" aria-label="Schreiben Sie uns ein E-Mail">
          <aside className={"icon"}>
            <MailIcon />
          </aside>
        </Obfuscate>
        <Obfuscate className="text" email="office@netengine.at" aria-label="Schreiben Sie uns ein E-Mail"/>
      </div>

      <Link className="footer-link mobile-only" to="/projekte" aria-label="Unsere Projekte anschauen">
        <div className={"footer-link"}>
          <aside className={"icon"}>
            <ProjectsIcon />
          </aside>
        </div>
      </Link>

      <a
        href="https://goo.gl/maps/VUVBRWMy9LSWfpmb8"
        className={"footer-link top"}
        aria-label="Adresse in Google Maps öffnen"
        target="_blank"
        rel="noopener noreferrer">
        <aside className={"icon"}>
          <LocationIcon />
        </aside>
        <address className="text">
          <ul>
            <li>
              <strong>.NETengine GmbH</strong>
            </li>
            <li>Kornmarktstraße 18</li>
            <li>6900 Bregenz</li>
            <li>Österreich</li>
          </ul>
        </address>
      </a>

      <div className={"footer-link support desktop-only"}>
        <Link to="/hilfe" className="footer-link" aria-label="Hilfe Seite öffnen">
          <aside className={"icon"}>
            <SupportIcon />
          </aside>
        </Link>
        <a
          href="https://get.teamviewer.com/de/yqp9437"
          aria-label="TeamViewer Software herunterladen"
          target="_blank"
          rel="noopener noreferrer"
        >
          <aside className={"download-link"}>
            <TeamViewerLogo />
          </aside>
        </a>

        <Link to="/hilfe" aria-label="Hilfe Seite öffnen">
          <aside className={"download-link"}>
            <HilfeLogo />
          </aside>
        </Link>
      </div>
    </nav>
  )
}

export default ContactBar
