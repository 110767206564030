/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { BreakpointsProvider } from "react-with-breakpoints"
import PropTypes from "prop-types"

import "../styles/reset.css"
import "../styles/variables.css"
import "../styles/typography.css"
import "../styles/base.css"
import "../styles/global.css"

import Navigation from "../components/navigation/Navigation"
import ContactBar from "../components/contactbar/ContactBar"
// import Footer from "../components/footer/Footer"


const Layout = ({ transparentnav, children }) => {
  return (
    <BreakpointsProvider>
      <Navigation transparent={transparentnav} />
      <main className="layout">{children}</main>
      <ContactBar />
      {/* <Footer /> */}
    </BreakpointsProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
