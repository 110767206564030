import React from "react"

import "./_ContactList.css"
import Obfuscate from 'react-obfuscate';

import MailIcon from "../../assets/svg/icons/mail.svg"
import PhoneIcon from "../../assets/svg/icons/phone.svg"
import LocationIcon from "../../assets/svg/icons/directions.svg"

const cl = () => {
  return (
    <nav className="contactlist">

      <div className={"contactlist-entry"}>
        <Obfuscate tel="0043557422538" aria-label="Schreiben Sie uns ein E-Mail">
          <aside className={"icon"}>
            <PhoneIcon />
          </aside>
        </Obfuscate>
        <Obfuscate className="text" tel="0043557422538" aria-label="Rufen Sie uns direkt an">
          +43 (0)5574 22538
        </Obfuscate>
      </div>

      <div className={"contactlist-entry"}>
        <Obfuscate email="office@netengine.at" aria-label="Schreiben Sie uns ein E-Mail">
          <aside className={"icon"}>
            <MailIcon />
          </aside>
        </Obfuscate>
        <Obfuscate className="text" email="office@netengine.at" />
      </div>

      <a
        href="https://goo.gl/maps/VUVBRWMy9LSWfpmb8"
        className={"contactlist-entry top"}
        aria-label="Adresse in Google Maps öffnen"
        target="_blank"
        rel="noopener noreferrer"
      >
        <aside className={"icon"}>
          <LocationIcon />
        </aside>
        <address className="text">
          <ul>
            <li>
              <strong>.NETengine GmbH</strong>
            </li>
            <li>Kornmarktstraße 18</li>
            <li>6900 Bregenz</li>
            <li>Österreich</li>
          </ul>
        </address>
      </a>
    </nav>
  )
}

export default cl;