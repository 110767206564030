import React from "react"
import { Link } from "gatsby"

const NavLink = ({linkName, to}) => (
    <Link to={to} getProps={({isPartiallyCurrent}) => isPartiallyCurrent ? { className: "active" } : null }>
      {linkName}
    </Link>
)

const nbl = ({sidebar}) => (
  <>
      <NavLink linkName="Projekte" to="/projekte" />
      <NavLink linkName="Leistungen" to="/leistungen" />
      <NavLink linkName="Jobs" to="/jobs" />
      <NavLink linkName="Team" to="/team" />
      {sidebar ? <NavLink linkName="Hilfe" to="/hilfe" /> : null}
      <NavLink linkName="Impressum" to="/impressum" />
  </>
)

export default nbl;