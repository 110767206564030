import React from "react"

import "./_Navigation.css"

import ContactList from "../contactlist/ContactList"
import Links from "./Links"

const ol = () => (
  <>
    <nav className="linklist text_big">
      <Links sidebar="true" />
    </nav>

    <ContactList />
  </>
)

export default ol;