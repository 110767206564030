import React, { useState } from "react"
import { Link } from "gatsby"
import { useTransition, animated } from "react-spring"

import Overlay from "./Overlay"
// import Footer from "../footer/Footer"
import Links from "./Links"

import "./_Navigation.css"
import BrandIcon from "../../assets/svg/logos/netengine-logo.svg"
import MenuIcon from "../../assets/svg/icons/nav.svg"
import CloseIcon from "../../assets/svg/icons/close.svg"

const Navigation = ({ transparent }) => {
  const [nav, toggle] = useState(false)
  const transitions = useTransition(nav, null, {
    from: { top: "-100%" },
    enter: { top: "0" },
    leave: { top: "-100%" },
  })
  return (
    <>
      <nav
        className={`navigation${nav ? " active" : ""}${
          transparent ? " transparent" : ""
          }`}
      >

        <Link to="/" label="Home">
          <BrandIcon className="brand" alt="Netengine GmbH" />
        </Link>
        <div className={"navbar"}>
          <Links />
          {!nav ? (
            <MenuIcon
              aria-label="Menu Open"
              className={"nav_open"}
              onClick={() => toggle(!nav)}
            />
          ) : (
              <CloseIcon
                aria-label="Menu Close"
                className={"nav_close"}
                onClick={() => toggle(!nav)}
              />
            )}
        </div>
      </nav>

      {transitions.map(
        ({ item, props, key }) =>
          item && (
            <animated.div style={props} key={key} className="overlay">
              <Overlay />
            </animated.div>
          )
      )}
      {/* <Footer /> */}
    </>
  )
}

export default Navigation
